import React, { useContext, useEffect } from 'react';
import { PageProps } from 'gatsby';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { LayoutBild, SEO } from '@components';
import Higher from '../components/PageLanding/Higher';
import SpaceText from '@components/PageLanding/SpaceText';
import Impacts from '@components/PageLanding/Impacts';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import FinancialProblems from '@components/PageLanding/FinancialProblems';
import Solutions from '@components/PageLanding/Solutions';
import { LoadingContext } from '@context';
import { getAuth } from 'firebase/auth';
import ContactLanding from '@components/PageLanding/newLanding/ContactLanding/ContactLanding';
import PartnersLanding from '@components/PageLanding/newLanding/PartnersLanding/PartnersLanding';

const SaveByRokinPage: React.FC<PageProps> = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1440px)');
  const { hideLoader } = useContext(LoadingContext);
  const currentUser = getAuth().currentUser;

  useEffect(() => {
    hideLoader('');
  }, [currentUser]);
  /* 
  window.onscroll = function () {
    scrollFunction();
  };
  const scrollFunction = () => {
    if ( 
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      setButton(true);
    } else {
      setButton(false);
    }
  }; */
  return (
    <>
      <SEO
        title='Rokin - Impacta las finanzas personales de tus equipos'
        description='Una solución global para las finanzas personales de tus colaboradores. La plataforma integral para mejorar sus hábitos financieros y el bienestar de tu empresa.'
      />
      <LayoutBild impactRokin key='Bottom'>
        <Higher />
        <SpaceText key='Space' />
        <Impacts />
        {/*         <HowIsWork />
         */}
        <FinancialProblems />
        <Solutions />
        <div className={classes.contact}>
          <ContactLanding />
        </div>
        <PartnersLanding />
        {/*  <DiscoverRokin /> */}
        {/*<FAQS />*/}
        {
          /* isButton && */ <a className={classes.top} key={1} href='#Top'>
            <ArrowUpwardIcon />
          </a>
        }
      </LayoutBild>
    </>
  );
};

export default SaveByRokinPage;

const useStyles = makeStyles((theme) => ({
  top: {
    padding: '1rem 1rem',
    color: theme.palette.primary.main,
    borderRadius: '100%',
    opacity: 0.8,
    '&:hover': {
      opacity: 1,
    },
    position: 'fixed',
    right: '1.3rem',
    bottom: '5rem',
    [theme.breakpoints.down(1240)]: {
      right: '0.6rem',
    },
    '& svg': {
      width: '2rem',
      height: '2rem',
    },
  },
  arrow: {
    height: 120,
    position: 'absolute',
    color: 'white',
    display: 'flex',
    margin: '0 auto',
    width: '99.5%',
    zIndex: 9,
    transform: 'rotate(1deg)',
  },
  contact: {
    boxShadow: '4px 4px 20px 2px rgba(0, 0, 0, 0.2)',
    borderRadius: 60,
  },
}));
