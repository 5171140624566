import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {},
  partners: {
    display: "flex",
    justifyContent: "center",
    gap: "5rem",
    margin: "4rem 0 6rem",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
      justifyContent: "center",
      gap: "2rem",
      alignItems: "center",
    },
    "& img": {
      width: "8rem",
      height: "4rem",
    },
  },
}));
