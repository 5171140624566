import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Order,
  Card,
  Graphic,
  impact2,
  impact3,
  impact1,
  impact4,
} from '@images';
import { ButtonPrimary } from '@components/Reusables';
import { navigate } from 'gatsby';
import { ORDER_IMG, VECTOR_IMG_2, VECTOR_IMG_3 } from 'images/GoogleCloud';
const Impacts: React.FC = () => {
  const classes = useStyles();

  const items = [
    {
      key: '1',
      image: impact2,
      title: 'Ofrece seguridad y protección financiera,',
      subsubtitle: 'Seguridad en cada paso',
      subtitle: (
        <>
          Elección de productos adecuados. <br />
          Recompensas por ahorrar e invertir.
          <br /> Administración de deudas y gastos.
        </>
      ),
      classImg: classes.img2,
      classTxt: classes.txt2,
      box: classes.box2,
      onClick: () => {},
      subImage: VECTOR_IMG_2,
      subClase: classes.subImg2b,
    },
    {
      key: '2',
      image: impact3,
      title: `Refuerza tu cultura organizacional`,
      subsubtitle: 'Impacto social',
      subtitle: `Impulsa la buena salud financiera.
Incentiva el trabajo en equipo con objetivos grupales.
Aumenta la retención y el compromiso.`,
      classImg: classes.img3,
      classTxt: classes.txt3,
      box: classes.box3,
      onClick: () => {},
      subImage: VECTOR_IMG_3,
      subClase: classes.subImg3b,
    },
  ];
  return (
    <section className={classes.container} id='Impacts'>
      <section className={classes.boxImage}>
        <img className={classes.img1} src={impact1} />
        <div className={`${classes.box} ${classes.box1}`}>
          <img className={classes.img} src={ORDER_IMG} />
        </div>
      </section>
      <div className={classes.txt1}>
        <div>
          <h3 className={classes.titleexception}>
            Entrega beneficios para todos
          </h3>
          <span className='subtitle'>Democratiza las finanzas</span>
          <span>
            Plataforma digital y al alcance de cada uno. <br /> Herramientas
            para entender tus finanzas.
            <br /> Acceso a mejores productos con tus buenos hábitos.
          </span>
        </div>
      </div>
      {items.map((item, i) => (
        <React.Fragment key={item.key}>
          <section className={classes.boxImage}>
            <img id={item.key} className={item.classImg} src={item.image} />
            <div className={`${classes.box} ${item.box}`}>
              <img className={item.subClase} src={item.subImage} />
            </div>
          </section>
          <div className={item.classTxt}>
            <div>
              <h3>{item.title}</h3>
              <span className='subtitle'>{item.subsubtitle}</span>
              <span>
                {' '}
                {i === 1 ? (
                  <>
                    Impulsa la buena salud financiera. <br />
                    Incentiva el trabajo en equipo con objetivos grupales.
                    <br />
                    Aumenta la retención y el compromiso.
                  </>
                ) : (
                  item.subtitle
                )}
              </span>
            </div>
            {/*     <ButtonOutlined
              className={classes.button}
              onClick={item.onClick}
              text='Quiero saber más'
            /> */}
          </div>
        </React.Fragment>
      ))}
      <div className={classes.txt4}>
        <div>
          <span>Monitorea el progreso</span>
          <span className='subtitle'>En todo momento</span>
          <span>
            Sigue el avance en tiempo real. <br /> Mide a través del Indice de
            Salud Financiera.
            <br /> Administra los beneficios de forma simple
          </span>
        </div>
        <a href='#Form'>
          <ButtonPrimary
            className={classes.button}
            text='Unir a mi empresa'
            noDegraded
            onClick={() => {
              navigate('/#Form');
            }}
          />
        </a>
      </div>
      <img src={impact4} className={classes.imageAbsolute} />
    </section>
  );
};

export default Impacts;

const useStyles = makeStyles((theme) => ({
  txt1: {
    gridArea: 'txt1',
  },
  txt2: {
    gridArea: 'txt2',
    paddingLeft: '4rem',
    [theme.breakpoints.down(1240)]: {
      paddingLeft: 0,
    },
  },
  container: {
    padding: '2rem 0 5rem',
    display: 'grid',
    position: 'relative',
    justifyContent: 'center',
    gridTemplateAreas: `'txt1 img1'
    'img2 txt2'
    'txt3 img3'
    'img4 txt4'`,
    gridTemplateColumns: '510px 500px',
    gap: '6rem 0rem',
    '& .subtitle': {
      display: 'block',
      paddingBottom: '1rem',
      color: theme.palette.primary.main,
      fontSize: 20,
      fontWeight: 300,
      fontFamily: 'Montserrat',
    },
    [theme.breakpoints.down(1240)]: {
      gridTemplateAreas: `
          'img2 ''txt1'
          'img1''txt2'
          'img3''txt3' 
          'imageAbsolute''txt4'`,
      gridTemplateColumns: 320,
      gap: '2rem 0rem',
      padding: '2rem 0 0rem',
    },
    [theme.breakpoints.down(400)]: {
      gridTemplateColumns: 330,
    },
    '& > div': {
      alignItems: 'center',
      [theme.breakpoints.down(1240)]: {
        fontSize: 26,
        width: 'auto',
        textAlign: 'start',
      },
      '& div': {
        '& :first-child': {
          fontFamily: 'Montserrat',
          fontSize: '2rem',
          fontWeight: 500,
          letterSpacing: 1.88,
          display: 'block',
          color: '#393e44',
          width: 423,
          padding: '24px 0 10px',
          [theme.breakpoints.down(1240)]: {
            fontSize: 26,
            width: 'auto',
            textAlign: 'start',
          },
        },
        '& :last-child': {
          fontFamily: theme.typography.fontFamily,
          fontSize: '1rem',
          fontWeight: 'normal',
          lineHeight: 1.75,
          color: '#7d7c7c',
          width: 380,
          display: 'block',
          [theme.breakpoints.down(1240)]: {
            width: 'auto',
            textAlign: 'start',
            fontSize: 16,
            marginBottom: '5rem',
          },
        },
      },
    },
    '&  div:last-child': {
      paddingLeft: 0,
    },
    '& >img': {
      minHeight: 380,
      [theme.breakpoints.down(1100)]: {
        margin: '0 auto',
      },
    },
  },
  txt3: {
    gridArea: 'txt3',
  },
  txt4: {
    gridArea: 'txt4',
  },
  img1: {
    gridArea: 'img1',
    background: theme.palette.secondary.main,
    borderRadius: 90,
    boxShadow: ' 4px 4px 20px 2px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down(1240)]: {
      borderRadius: 50,
    },
  },
  img2: {
    gridArea: 'img2',
    background: theme.palette.secondary.main,
    borderRadius: 90,
    maxWidth: 500,
    boxShadow: ' 4px 4px 20px 2px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down(1240)]: {
      width: 300,
      marginLeft: 30,
      borderRadius: 50,
    },
  },
  img3: {
    gridArea: 'img3',
    background: theme.palette.secondary.main,
    borderRadius: 90,
    boxShadow: ' 4px 4px 20px 2px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down(1240)]: {
      width: 375,
      borderRadius: 50,
    },
  },
  img4: {
    gridArea: 'img4',
    [theme.breakpoints.down(1240)]: {
      width: 375,
      borderRadius: 50,
    },
  },
  button: {
    width: 184,
    marginTop: '5rem',
    cursor: 'pointer',
    zIndex: 3,
    background: '#00323C',
  },
  imageAbsolute: {
    gridArea: 'imageAbsolute',
    position: 'absolute',
    bottom: -180,
    width: 1100,
    right: '40%',
    zIndex: 1,
    [theme.breakpoints.down(1240)]: {
      position: 'relative',
      width: 400,
      right: 'auto',
      bottom: 'auto',
    },
  },
  boxImage: {
    position: 'relative',
  },
  box: {
    position: 'absolute',
  },
  box1: {
    width: 285,
    height: 235,
    top: 20,
    right: -100,
    [theme.breakpoints.down(1240)]: {
      width: 195,
      top: -45,
      right: -20,
    },
  },
  img: {
    width: '280px',
  },
  box1b: {
    width: 285,
    height: 75,
    top: 270,
    right: -100,
    [theme.breakpoints.down(1240)]: {
      top: 100,
      width: 160,
      height: 50,
      right: -20,
      borderRadius: 20,
    },
  },
  box2: {
    width: 300,
    height: 136,
    top: 70,
    left: -100,
    [theme.breakpoints.down(1240)]: {
      width: 160,
      top: 40,
      left: -35,
      height: 70,
      borderRadius: 20,
    },
  },
  box3: {
    width: 300,
    height: 200,
    top: -30,
    right: -90,
    [theme.breakpoints.down(1240)]: {
      width: 250,
      height: 135,
      top: -45,
      right: -17,
    },
  },
  box4: {
    width: 300,
    height: 136,
    top: 70,
    left: -100,
    [theme.breakpoints.down(1240)]: {
      width: 160,
      top: 40,
      left: -18,
      height: 70,
      borderRadius: 20,
    },
  },
  cardCopper: {
    top: 35,
    position: 'absolute',
    left: 30,
    width: 85,
    [theme.breakpoints.down(1240)]: {
      top: 10,
      left: 5,
      width: 60,
    },
  },
  cardSilver: {
    top: 35,
    position: 'absolute',
    right: 35,
    width: 85,
    [theme.breakpoints.down(1240)]: {
      top: 10,
      right: 5,
      width: 60,
    },
  },
  cardGold: {
    top: 25,
    position: 'absolute',
    width: 120,
    left: 80,
    zIndex: 99,
    [theme.breakpoints.down(1240)]: {
      top: 8,
      left: 40,
      width: 80,
    },
  },
  order: {
    position: 'absolute',
    top: 11,
    left: 16,
    width: 260,
    [theme.breakpoints.down(1240)]: {
      width: 140,
      left: 10,
    },
  },
  subImg2b: {
    position: 'absolute',
    top: -60,
    left: 25,
    width: 280,
    [theme.breakpoints.down(1240)]: {
      top: -30,
      left: 25,
      width: 110,
    },
  },
  subImg3b: {
    position: 'absolute',
    top: 0,
    right: 10,
    width: 300,
    [theme.breakpoints.down(1240)]: {
      width: 210,
      right: 0,
    },
  },
  titleexception: {
    width: '350px!important',
  },
}));
