import React from "react";
import { makeStyles } from "@material-ui/core";
import { financial, porcentage } from "@images";

const FinancialProblems: React.FC = () => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <img src={financial} className={classes.financial} />
      <img src={porcentage} className={classes.porcentage} />
    </section>
  );
};

export default FinancialProblems;

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "8rem",
    marginBottom: "4rem",
    position: "relative",
    [theme.breakpoints.down(1240)]: {
      objectFit: "cover",
    },
  },
  financial: {
    width: "100%",
    filter: "blur(5px)",
    [theme.breakpoints.down(1240)]: {
      height: 300,
    },
  },
  porcentage: {
    position: "absolute",
    top: 58,
    right: 48,
    [theme.breakpoints.down(1380)]: {
      top: 20,
      right: 20,
    },
    [theme.breakpoints.down(1240)]: {
      display: "flex",
      justifyContent: "center",
      width: 400,
      margin: "0 auto",
      position: "relative",
      top: -270,
      right: "auto",
    },
  },
}));
