import React, { useContext } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useStyles } from './ContactLanding.style';
import { SaveByRokinContext } from '@context';
import Contact from '@components/PageLanding/Contact';
import { ButtonOutlined, ButtonPrimary } from '@components/Reusables';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';

interface propsContact {
  noForm?: boolean;
}

const ContactLanding = ({ noForm }: propsContact) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1240px)');
  const { isAlreadyCreated } = useContext(SaveByRokinContext);

  return (
    <section className={classes.container}>
      <div className={classes.flex}>
        <div className={classes.left}>
          <div className={classes.title}>
            {noForm
              ? 'Todas las herramientas al alcance de tu mano'
              : 'Pongámonos en contacto!'}
          </div>
          <div className={!noForm ? classes.subtitle : classes.noFormSubtitle}>
            {noForm
              ? 'Tenemos alianzas con las mejores empresas para entregarte una experiencia personalizada que potencie tus finanzas.'
              : ' Conversemos para hacerte parte de nuestro objetivo'}
          </div>
        </div>
        <div className={!noForm ? classes.right : classes.noForm}>
          {/*  <div className={classes.subtitle2}>
            <span>Sé el primero</span> en acceder a los beneficios que Rokin
            tiene para ti y <span>participa por un Alexa Echo Dot.</span>
          </div> */}
          {noForm ? (
            <div className={classes.containerButtons}>
              <ButtonOutlined
                className='firstButton'
                onClick={() => {
                  navigate(Route.register);
                }}
                text='Empezar'
              />
              <ButtonPrimary
                className='lastButton'
                text=''
                onClick={() => {
                  navigate(Route.register);
                }}
                noDegraded
                startIcon={
                  <img src='https://rokinapp-images.s3.amazonaws.com/icons/flecha_icon.svg' />
                }
              />
            </div>
          ) : (
            <div className={classes.email}>
              {' '}
              <Contact />
            </div>
          )}
          {isAlreadyCreated && (
            <section className={classes.mensaje}>
              ¡Ya estás inscrito para el lanzamiento de Rokin!
            </section>
          )}
        </div>
      </div>
      <div className={classes.partners}>
        {icons.map((icon) => {
          return (
            <div key={icon.id}>
              <img src={icon.image} alt='' />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ContactLanding;

const icons = [
  {
    id: 0,
    image:
      'https://rokinapp-images.s3.amazonaws.com/Landing/partners/vector_logo.svg',
  },
  {
    id: 1,
    image:
      'https://rokinapp-images.s3.amazonaws.com/Landing/partners/bancaMe_logo.svg',
  },

  {
    id: 2,
    image:
      'https://rokinapp-images.s3.amazonaws.com/Landing/partners/coolebra_logo.svg',
  },
  {
    id: 3,
    image:
      'https://storage.googleapis.com/rokinapp-images/Landing/partners/12tren_logo.svg',
  },
  {
    id: 4,
    image:
      'https://rokinapp-images.s3.amazonaws.com/Landing/partners/semso_logo.svg',
  },
];
