import React from "react";
import { makeStyles } from "@material-ui/core";
import { Found } from "@images";
const SpaceText: React.FC = () => {
  const classes = useStyles();

  return (
    <h2 className={classes.container}>
      <span className={classes.title}>
        <span className={classes.impact}>Impacta</span> a tus equipos
      </span>
      <span className={classes.subtitle}>
        Entregándoles
        <span> herramientas y beneficios </span> que les permitan tener mayor
        <span> conocimiento y control</span> sobre sus finanzas personales, para
        mejorar sus <span> hábitos financieros </span>a través de la
        planificación y el aprendizaje.
      </span>
    </h2>
  );
};

export default SpaceText;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "11rem 0",
    [theme.breakpoints.down(1240)]: {
      marginTop: "7rem",
      padding: "11rem 0 7rem",
    },
  },
  title: {
    color: theme.palette.primary.dark,
    fontSize: "4rem",
    width: "35.2rem",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    lineHeight: 1.4,
    display: "block",
    textAlign: "center",
    paddingBottom: "4.5rem",
    [theme.breakpoints.down(1240)]: {
      fontSize: "3rem",
      width: 290,
      paddingBottom: "2rem",
    },
  },
  subtitle: {
    color: "#008296",
    fontSize: "1.5rem",
    width: 935,
    fontFamily: "Montserrat",
    lineHeight: 1.75,
    display: "block",
    textAlign: "center",
    fontWeight: "normal",
    [theme.breakpoints.down(1240)]: {
      fontSize: 12,
      width: 285,
    },
    "& span": {
      fontWeight: 600,
    },
  },
  impact: {
    background: `url(${Found})`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundPosition: "center",
  },
}));
