import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useStyles } from './PartnersLanding.style';

const PartnersLanding: React.FC = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1240px)');

  return (
    <div className={classes.partners}>
      {icons.map((icon) => {
        return (
          <div key={icon.id}>
            <img src={icon.image} alt='' />
          </div>
        );
      })}
    </div>
  );
};

export default PartnersLanding;

const icons = [
  {
    id: 6,
    image:
      'https://rokinapp-images.s3.amazonaws.com/Landing/partners/uddVentures_logo.svg',
  },
  {
    id: 7,
    image:
      'https://rokinapp-images.s3.amazonaws.com/Landing/partners/oracle_logo.svg',
  },
  {
    id: 8,
    image:
      'https://rokinapp-images.s3.amazonaws.com/Landing/partners/villageCapital_logo.svg',
  },
];
