import React, { useCallback, useContext, useState } from "react";
import {
  ButtonPrimary,
  EmailInput,
  NameInput,
  ReasonContact,
  SurnamesInput,
  TextInput,
} from "@components";
import { makeStyles } from "@material-ui/core";
import { ChangeEvent } from "@interfaces";
import { LoadingContext } from "@context";
import { sendContactForm } from "@apollo";
import { useApolloClient } from "@apollo/client";

const Contact: React.FC = () => {
  const [email, setEmail] = useState("");
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const classes = useStyles();
  const changeEmail = useCallback(
    (e: ChangeEvent) => {
      const value = e.target.value as string;
      setEmail(value);
    },
    [setEmail]
  );
  const [topic, setTopic] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [name, setName] = useState("");
  const [sector, setSector] = useState("");
  const [surname, setSurname] = useState("");
  const [sendForm, setSendForm] = useState(false);
  const client = useApolloClient();

  const onSend = useCallback(() => {
    showLoader("contact modal");
    sendContactForm(
      {
        name,
        email,
        sector,
        surname,
        companyName,
        topic,
      },
      client
    ).then(() => {
      setCompanyName("");
      setName("");
      setSector("");
      setSurname("");
      setTopic("");
      setSendForm(true);
      hideLoader("contact modal");
      setCompanyName("");
      setName("");
      setSector("");
      setSurname("");
      setTopic("");
      setSendForm(true);
    });
  }, [name, email, sector, surname, companyName, topic, sendForm]);

  const disabled = topic && companyName && name && sector && surname;

  return (
    <section className={classes.container} id="Form">
      <section>
        <div className={classes.form}>
          <div>
            <NameInput
              value={name}
              onChange={(e) => {
                setName(e.target.value as string);
                setSendForm(false);
              }}
            />
            <SurnamesInput
              value={surname}
              onChange={(e) => {
                setSurname(e.target.value as string);
                setSendForm(false);
              }}
            />
            <EmailInput value={email} onChange={changeEmail} />
            <TextInput
              value={companyName}
              label="Nombre de tu empresa"
              onChange={(e) => {
                setCompanyName(e.target.value as string);
                setSendForm(false);
              }}
            />
            <TextInput
              label="Sector"
              value={sector}
              onChange={(e) => {
                setSector(e.target.value as string);
                setSendForm(false);
              }}
            />
            <ReasonContact
              value={topic}
              onChange={(e) => {
                setTopic(e.target.value as string);
                setSendForm(false);
              }}
            />
          </div>
          {sendForm && (
            <div className={classes.sendForm}>Formulario enviado</div>
          )}
          <div className={classes.button}>
            <ButtonPrimary
              disabled={!disabled}
              noDegraded
              onClick={onSend}
              text="Enviar"
            />
          </div>
        </div>
      </section>
    </section>
  );
};

export default Contact;

const useStyles = makeStyles((theme) => ({
  container: {
    "& section": {
      display: "flex",
      justifyContent: "center",
    },
  },
  sendForm: {
    position: "absolute",
    bottom: "0.5em",
    left: "1rem",
    [theme.breakpoints.down(1240)]: {
      left: "0rem",
      bottom: "-1rem",
      textAlign: "center",
    },
  },
  top: {
    paddingLeft: "13%",
    [theme.breakpoints.down(1240)]: {
      paddingLeft: 20,
    },
  },
  title: {
    display: "block",
    fontSize: "2rem",
    lineHeight: 2,
    fontWeight: "bold",
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    [theme.breakpoints.down(1240)]: {
      color: "#F4F3F3",
      lineHeight: 1.25,
    },
  },
  subtitle: {
    fontFamily: "Montserrat",
    lineHeight: 1.4,
    fontSize: "1.25rem",
    color: "#7d7c7c",
    [theme.breakpoints.down(1240)]: {
      fontSize: 14,
      display: "block",
      paddingTop: 10,
      width: 230,
      color: "#F4F3F3",
      lineHeight: 1.57,
      paddingBottom: "1rem",
    },
  },
  form: {
    position: "relative",
    "& >div": {
      display: "grid",
      gridTemplateColumns: "320px 320px",
      justifyContent: "center",
      gap: "1rem 3rem",
      width: 688,
      margin: "0 auto",
      [theme.breakpoints.down(1240)]: {
        gridTemplateColumns: "100%",
        justifyContent: "center",
        width: "100%",
      },
      "& >div": {
        background: "white",
        borderRadius: 14,
        [theme.breakpoints.down(1240)]: {
          maxWidth: 215,
          width: 215,
          margin: "0 auto",
          background: "white",
          borderRadius: 5,
        },
      },
    },
    borderRadius: 30,
    [theme.breakpoints.down(1240)]: {},
  },
  button: {
    textAlign: "end",
    display: "block!important",
    marginBottom: "5rem",
    [theme.breakpoints.down(1240)]: {
      paddingTop: "2rem",
      display: "flex!important",
      justifyContent: "center",
    },
    "& button": {
      marginTop: "2rem",
      width: 200,
      [theme.breakpoints.down(1240)]: {
        marginBottom: "2rem",
        width: 186,
        margin: "0 auto",
        background: "#00a9c2",
      },
    },
  },
}));
