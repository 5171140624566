import React from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import {
  Found,
  SOLUTION_ICON_1,
  SOLUTION_ICON_2,
  SOLUTION_ICON_3,
  SOLUTION_ICON_4,
} from '@images';
import { ButtonPrimary } from '..';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';

const Solutions: React.FC = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1240px)');

  return (
    <section className={classes.container}>
      <div className={classes.containerTotal}>
        <span className={classes.title}>
          Nuestras <span className={classes.principal}>soluciones</span>
        </span>
        <span className={classes.subtitle}>
          Tus decisiones financieras en un sólo lugar
        </span>
      </div>
      <div className={classes.items}>
        <div
          className={classes.solutions}
          onClick={() => navigate(Route.ourSolutions + '#s0')}
        >
          <img src={SOLUTION_ICON_1} />{' '}
          <span className='nameSection'>Ahorro</span>
          {!isMobile && (
            <ButtonPrimary
              className='button'
              onClick={() => navigate(Route.ourSolutions + '#s0')}
              noDegraded
              text='Quiero saber más'
            />
          )}
        </div>
        <div
          className={classes.solutions}
          onClick={() => navigate(Route.ourSolutions + '#s1')}
        >
          <img src={SOLUTION_ICON_2} />{' '}
          <span className='nameSection'>Administración</span>
          {!isMobile && (
            <ButtonPrimary
              onClick={() => navigate(Route.ourSolutions + '#s1')}
              noDegraded
              text='Quiero saber más'
            />
          )}
        </div>
        <div
          className={classes.solutions}
          onClick={() => navigate(Route.ourSolutions + '#s2')}
        >
          <img src={SOLUTION_ICON_3} />{' '}
          <span className='nameSection'>Aprendizaje</span>
          {!isMobile && (
            <ButtonPrimary
              onClick={() => navigate(Route.ourSolutions + '#s2')}
              noDegraded
              text='Quiero saber más'
            />
          )}
        </div>{' '}
        <div
          className={classes.solutions}
          onClick={() => navigate(Route.ourSolutions + '#s2')}
        >
          <img src={SOLUTION_ICON_4} />{' '}
          <span className='nameSection'>Acompañamiento</span>
          {!isMobile && (
            <ButtonPrimary
              onClick={() => navigate(Route.ourSolutions + '#s2')}
              noDegraded
              text='Quiero saber más'
            />
          )}
        </div>
      </div>{' '}
      {isMobile && (
        <ButtonPrimary onClick={() => {}} noDegraded text='Quiero saber más' />
      )}
    </section>
  );
};

export default Solutions;

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '7rem 0 9rem',
    position: 'relative',
    textAlign: 'center',
    '& >button': {
      marginTop: '3rem',
      background: theme.palette.primary.dark,
    },
  },
  financial: {
    width: '100%',
  },
  solutions: {
    '& .button': {
      marginTop: '2rem',
    },
    '& img': {
      width: '8rem!important',
      margin: '0 auto',
    },
    '& .nameSection': {
      display: 'block',
      padding: '1rem',
      fontFamily: 'Montserrat',
      fontSize: '1rem',
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },
  },
  principal: {
    background: `url(${Found})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundPosition: 'center',
    fontFamily: 'Montserrat',
  },
  containerTotal: {
    margin: '0 auto',
    color: theme.palette.text.primary,
    [theme.breakpoints.down(1240)]: {
      width: 375,
    },
  },
  title: {
    fontSize: 64,
    fontWeight: 'bold',
    display: 'block',
    fontFamily: 'Montserrat',
    [theme.breakpoints.down(1240)]: {
      fontSize: 48,
    },
  },
  subtitle: {
    fontSize: 32,
    fontFamily: 'Montserrat',
    fontWeight: 500,
    [theme.breakpoints.down(1240)]: {
      fontSize: 16,
      width: 230,
      display: 'block',
      marginTop: '2rem',
      margin: '0 auto',
    },
  },
  items: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    gap: '6rem',
    marginTop: '3rem',
    [theme.breakpoints.down(1240)]: {
      width: 400,
      flexWrap: 'wrap',
      gap: '2rem',
      justifyContent: 'center',
    },
    '& img': {
      width: 250,
      [theme.breakpoints.down(1240)]: {
        width: 200,
      },
    },
    '& div': {
      display: 'grid',
      justifyContent: 'center',
      cursor: 'pointer',

      '& button': {
        width: 177,
        marginTop: '2rem',
        background: theme.palette.primary.dark,
        [theme.breakpoints.down(1240)]: {
          width: 140,
          fontSize: 12,
          marginLeft: 40,
        },
      },
    },
  },
}));
